<template>
  <div class="home" :class="userLanguage">
    <home-header></home-header>
    <section class="page page-main">
      <div class="main-wrap">
        <div class="image-wrap">
          <img class="phone-bg-image" src="@/assets/images/home/phone-bg.png" alt="phone">
          <div class="screen-wrap">
            <img class="phone-screen-image" :src="require(`@/assets/images/home/phoneScreen/phone-screen-${userLanguage}.png`)" alt="screenshot">
          </div>
        </div>
        <div class="slogan-wrap">
          <h1 class="slogan">{{$t('web.slogan')}}</h1>
          <p class="guide-text">{{$t('web.slogan2')}}</p>
          <div class="button-wrap">
            <div class="startLink" @click="startHandle">{{$t('main.enter')}}</div>
          </div>
        </div>
      </div>
    </section>
    <section class="page page-description">
      <h2 class="section-title">{{$t('web.title1')}}</h2>
      <div class="description-wrap">
        <div class="description-item">
          <img class="description-item-image" src="@/assets/images/home/advantage/advantage-1.png" alt="advantage">
          <p class="description-item-title">{{$t('web.page1_title1')}}</p>
          <p class="description-item-text">{{$t('web.page1_content1')}}</p>
        </div>
        <div class="description-item">
          <img class="description-item-image" src="@/assets/images/home/advantage/advantage-2.png" alt="advantage">
          <p class="description-item-title">{{$t('web.page1_title2')}}</p>
          <p class="description-item-text">{{$t('web.page1_content2')}}</p>
        </div>
        <div class="description-item">
          <img class="description-item-image" src="@/assets/images/home/advantage/advantage-3.png" alt="advantage">
          <p class="description-item-title">{{$t('web.page1_title3')}}</p>
          <p class="description-item-text">{{$t('web.page1_content3')}}</p>
        </div>
        <div class="description-item">
          <img class="description-item-image" src="@/assets/images/home/advantage/advantage-4.png" alt="advantage">
          <p class="description-item-title">{{$t('web.page1_title4')}}</p>
          <p class="description-item-text">{{$t('web.page1_content4')}}</p>
        </div>
        <div class="description-item">
          <img class="description-item-image" src="@/assets/images/home/advantage/advantage-5.png" alt="advantage">
          <p class="description-item-title">{{$t('web.page1_title5')}}</p>
          <p class="description-item-text">{{$t('web.page1_content5')}}</p>
        </div>
        <div class="description-item">
          <img class="description-item-image" src="@/assets/images/home/advantage/advantage-6.png" alt="advantage">
          <p class="description-item-title">{{$t('web.page1_title6')}}</p>
          <p class="description-item-text">{{$t('web.page1_content6')}}</p>
        </div>
        <div class="description-item">
          <img class="description-item-image" src="@/assets/images/home/advantage/advantage-7.png" alt="advantage">
          <p class="description-item-title">{{$t('web.page1_title7')}}</p>
          <p class="description-item-text">{{$t('web.page1_content7')}}</p>
        </div>
        <div class="description-item">
          <img class="description-item-image" src="@/assets/images/home/advantage/advantage-8.png" alt="advantage">
          <p class="description-item-title">{{$t('web.page1_title8')}}</p>
          <p class="description-item-text">{{$t('web.page1_content8')}}</p>
        </div>
      </div>
    </section>
    <section class="page page-screenshot">
      <div class="screenshot-wrap">
        <div class="screenshot-item">
          <p class="screenshot-item-title">{{$t('web.page2_title1')}}</p>
          <p class="screenshot-item-text">{{$t('web.page2_content1')}}</p>
          <img class="screenshot-item-image" src="@/assets/images/home/screenshot/screenshot-1.png" alt="screenshot">
        </div>
        <div class="screenshot-item">
          <p class="screenshot-item-title">{{$t('web.page2_title2')}}</p>
          <p class="screenshot-item-text">{{$t('web.page2_content2')}}</p>
          <img class="screenshot-item-image" src="@/assets/images/home/screenshot/screenshot-2.png" alt="screenshot">
        </div>
        <div class="screenshot-item">
          <p class="screenshot-item-title">{{$t('web.page2_title3')}}</p>
          <p class="screenshot-item-text">{{$t('web.page2_content3')}}</p>
          <img class="screenshot-item-image" src="@/assets/images/home/screenshot/screenshot-3.png" alt="screenshot">
        </div>
        <div class="screenshot-item">
          <p class="screenshot-item-title">{{$t('web.page2_title4')}}</p>
          <p class="screenshot-item-text">{{$t('web.page2_content4')}}</p>
          <img class="screenshot-item-image" src="@/assets/images/home/screenshot/screenshot-4.png" alt="screenshot">
        </div>
        <div class="screenshot-item">
          <p class="screenshot-item-title">{{$t('web.page2_title5')}}</p>
          <p class="screenshot-item-text">{{$t('web.page2_content5')}}</p>
          <img class="screenshot-item-image" src="@/assets/images/home/screenshot/screenshot-5.png" alt="screenshot">
        </div>
      </div>
    </section>
    <section class="page page-review">
      <h2 class="section-title">{{$t('web.title2')}}</h2>
      <div class="review-wrap">
        <swiper class="swiper" :options="swiperOptions">
          <swiper-slide class="review-item">
            <div class="review-item-header">
              <img src="@/assets/images/home/header/header-2.png" alt="user-header">
            </div>
            <div class="review-item-name">Allison Murdoch</div>
            <div class="review-item-from">{{$t('web.page3_country6')}}</div>
            <div class="review-item-stars">
              <img src="@/assets/images/home/star.png" alt="star" v-for="i in 5" :key="i">
            </div>
            <div class="review-item-content">I have just started using the app so far so good. Very interesting how the movie clips are integrated alongside the little talkative figureheads that speak after every phrase during a lesson. </div>
          </swiper-slide>
          <swiper-slide class="review-item">
            <div class="review-item-header">
              <img src="@/assets/images/home/header/header-1.png" alt="user-header">
            </div>
            <div class="review-item-name">Santiago</div>
            <div class="review-item-from">{{$t('web.page3_country1')}}</div>
            <div class="review-item-stars">
              <img src="@/assets/images/home/star.png" alt="star" v-for="i in 5" :key="i">
            </div>
            <div class="review-item-content">¡Me encanta esta app! En verdad, es de las mejores que he encontrado. AI poco tiempo ya sé decir muchas cosas en inglés. Es muy interactiva y divertida. La recomiendo ampliamente. ¡Muchas gracias a todo el equipo por el esfuerzo que le han puesto!</div>
          </swiper-slide>
          <swiper-slide class="review-item">
            <div class="review-item-header">
              <img src="@/assets/images/home/header/header-3.png" alt="user-header">
            </div>
            <div class="review-item-name">Лидия</div>
            <div class="review-item-from">{{$t('web.page3_country3')}}</div>
            <div class="review-item-stars">
              <img src="@/assets/images/home/star.png" alt="star" v-for="i in 5" :key="i">
            </div>
            <div class="review-item-content">Очень интересное приложение, небанальное) Клипы классно подобраны, люблю за ними повторять слова и фразы с разными интонациями))</div>
          </swiper-slide>
          <swiper-slide class="review-item">
            <div class="review-item-header">
              <img src="@/assets/images/home/header/header-4.png" alt="user-header">
            </div>
            <div class="review-item-name">fvvvbju</div>
            <div class="review-item-from">{{$t('web.page3_country6')}}</div>
            <div class="review-item-stars">
              <img src="@/assets/images/home/star.png" alt="star" v-for="i in 5" :key="i">
            </div>
            <div class="review-item-content">I literally love this app, they make learning languages so fun I've been on it for hours now😭💗</div>
          </swiper-slide>
          <swiper-slide class="review-item">
            <div class="review-item-header">
              <img src="@/assets/images/home/header/header-5.png" alt="user-header">
            </div>
            <div class="review-item-name">サクラ</div>
            <div class="review-item-from">{{$t('web.page3_country5')}}</div>
            <div class="review-item-stars">
              <img src="@/assets/images/home/star.png" alt="star" v-for="i in 5" :key="i">
            </div>
            <div class="review-item-content">ドラマや映画を観る感覚でクリップを見ながら学習できるのでとても気に入っています！クリップで覚えた単語やフレーズをすぐに実践できるように、活用シーンをいくつかクリップで紹介してくれるのもすごく役に立っています！</div>
          </swiper-slide>
          <div class="swiper-pagination"></div>
        </swiper>
        <div class="swiper-button swiper-button-prev">
          <img src="@/assets/images/home/arrow.png" class="arrow">
        </div>
        <div class="swiper-button swiper-button-next">
          <img src="@/assets/images/home/arrow.png" class="arrow">
        </div>
      </div>
    </section>
    <section class="page page-download">
      <h2 class="section-title">{{$t('web.title3')}}</h2>
      <img class="logo-icon" src="@/assets/images/home/logo-icon.png" alt="logo-icon">
      <div class="download-wrap">
        <a class="download-link" href="https://play.google.com/store/apps/details?id=com.enpal" target="_blank"><i class="iconfont icon-GooglePlaybeifen23x"></i></a>
        <a class="download-link" href="https://apps.apple.com/us/app/id1603548515" target="_blank"><i class="iconfont icon-AppleStorebeifen23x"></i></a>
      </div>
    </section>
    <home-footer class="page"></home-footer>
  </div>
</template>

<script>
  import Header from '@/components/Header.vue'
  import Footer from '@/components/Footer.vue'
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
  import 'swiper/css/swiper.css'
  import Vuex from 'vuex'

  export default {
    name: 'Home',
    data() {
      return {
        swiperOptions: {
          slideActiveClass: 'slide-active',
          slideDuplicateClass: 'slide-duplicate',
          watchSlidesProgress: true,
          slidesPerView: 'auto',
          centeredSlides: true,
          loop: true,
          loopedSlides: 3,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          on: {
            progress: function () {
              for (let i = 0; i < this.slides.length; i++) {
                var slide = this.slides.eq(i);
                var slideProgress = this.slides[i].progress;
                let modify = 0.88;
                let translate = slideProgress * modify * 120 + 'px';
                let scale = 1 - Math.abs(slideProgress) / 7;
                let zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
                slide.transform('translateX(' + translate + ') scale(' + scale + ')');
                slide.css('zIndex', zIndex);
                slide.css('opacity', 1);
                if (Math.abs(slideProgress) === 1) {
                  slide.css('opacity', 0.9);
                }
                if (Math.abs(slideProgress) === 2) {
                  slide.css('opacity', 0.8);
                }
                if (Math.abs(slideProgress) >= 3) {
                  slide.css('opacity', 0);
                }
              }
            },
            setTransition: function (transition) {
              for (var i = 0; i < this.slides.length; i++) {
                var slide = this.slides.eq(i)
                slide.transition(transition);
              }
            }
          }
        }
      }
    },
    components: {
      'home-header': Header,
      'home-footer': Footer,
      Swiper,
      SwiperSlide
    },
    computed: {
      ...Vuex.mapGetters(['userLanguage'])
    },
    methods: {
      startHandle() {
        this.$router.push('/start')
      }
    }
  }
</script>

<style lang="scss" scoped>
  @font-face {
    font-family: 'Hiragino Maru Gothic ProN';
    src: url('../assets/fonts/ヒラギノ丸ゴ ProN W4.otf');
    font-display: swap;
  }

  .home {
    font-family: Arial-Black, Arial;

    &.jp {
      font-family: 'Hiragino Maru Gothic ProN';
      .startLink,
      .guide-text,
      .description-item-title,
      .description-item-text,
      .screenshot-item-title,
      .screenshot-item-text {
        font-family: unset !important;
      }

      .guide-text {
        font-size: 22px !important;
      }
    }

    .slogan {
      font-size: 36px;
      font-weight: 900;
      color: #FFFFFF;
      line-height: 68px;
      letter-spacing: -1.3px;
      white-space: nowrap;
    }

    .startLink {
      min-width: 240px;
      height: 60px;
      line-height: 60px;
      background: #FFE113;
      border-radius: 8px;
      font-size: 22px;
      font-family: Arial;
      font-weight: bold;
      color: #060606;
      text-align: center;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        background: #FFBC1A;
      }
    }

    .section-title {
      font-size: 30px;
      font-weight: 900;
      color: #0B2550;
      text-align: center;
      margin: 0 auto;

      &:before {
        content: '';
        display: inline-block;
        width: 4px;
        height: 30px;
        position: relative;
        top: 4px;
        right: 20px;
        background: #1B90FB;
        border-radius: 4px;
      }

      &:after {
        content: '';
        display: inline-block;
        width: 4px;
        height: 30px;
        position: relative;
        top: 4px;
        left: 20px;
        background: #1B90FB;
        border-radius: 4px;
      }
    }

    .page {
      min-width: 1300px;
    }

    .page-main {
      background: url(../assets/images/home/main-page-bg.png) center bottom/1920px no-repeat, linear-gradient(0deg, #070F5C 0%, #1E33C2 100%);
      margin-top: 80px;
      height: 736px;

      .main-wrap {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        max-width: 1000px;
        min-width: 880px;
        height: 100%;

        .image-wrap {
          flex-shrink: 0;
          position: relative;
          width: 297px;
          height: 100%;

          .phone-bg-image {
            z-index: 1;
            position: absolute;
            top: 96px;
            left: 0;
            width: 100%;
          }

          .screen-wrap {
            z-index: 2;
            position: absolute;
            top: 170px;
            left: 16px;
            width: 265px;
            height: 446px;
            overflow: hidden;

            .phone-screen-image {
              width: 100%;
              height: auto;
              animation: move-screen 4s ease-in-out infinite alternate;

              @keyframes move-screen {
                0% {
                  transform: translateY(0)
                }

                10% {
                  transform: translateY(0)
                }

                90% {
                  transform: translateY(-468px)
                }

                100% {
                  transform: translateY(-468px)
                }
              }
            }
          }
        }

        .slogan-wrap {
          position: relative;
          width: 650px;
          text-align: left;

          .slogan {
            margin-top: 220px;
          }

          .guide-text {
            white-space: pre-line;
            margin-top: 24px;
            font-size: 24px;
            font-family: Helvetica;
            color: #FFFFFF;
            line-height: 34px;
          }

          .button-wrap {
            position: absolute;
            bottom: 160px;
            display: flex;

            .download-link {
              display: block;
              width: 174px;
              height: 56px;
              background: #FFFFFF;
              border-radius: 10px;
              text-decoration: none;
              text-align: center;
              margin-right: 40px;

              &:hover {
                background: #D5E4FF;
              }

              i {
                color: #2533BD;
                font-size: 56px;
                line-height: 54px;
              }
            }
          }
        }
      }
    }

    .page-description {
      padding-top: 75px;
      padding-bottom: 115px;
      background: #FCFCFF;

      .description-wrap {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 1200px;
        margin: 60px auto 0;

        .description-item {
          width: 280px;
          height: 280px;
          padding: 10px;
          box-sizing: border-box;
          text-align: center;
          transition: box-shadow .3s ease;
          border-radius: 18px;

          &:hover {
            cursor: pointer;
            box-shadow: 0 2px 32px 0 rgba(0, 138, 208, 0.27);
          }

          &-image {
            width: 160px;
          }

          &-title {
            font-family: Helvetica-Bold;
            font-weight: Bold;
            font-size: 20px;
            color: #444444;
          }

          &-text {
            margin-top: 15px;
            font-family: PingFangSC-Medium;
            font-weight: 500;
            font-size: 16px;
            color: #6B6B6B;
            line-height: 27px;
            white-space: pre-line;
          }
        }
      }
    }

    .page-screenshot {
      background: url(../assets/images/home/screenshot-page-bg.png) center top/1621px no-repeat, linear-gradient(130deg, #3F49CF, #5E3CC7);
      height: 577px;

      .screenshot-wrap {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 1300px;
        padding-top: 54px;
        margin: 0 auto;
        box-sizing: border-box;
        height: 100%;
        overflow: hidden;

        .screenshot-item {
          width: 260px;
          height: 100%;
          box-sizing: border-box;
          text-align: center;

          &-title {
            height: 70px;
            font-family: PingFangSC-Heavy;
            font-weight: 900;
            font-size: 24px;
            color: #FFFFFF;
            transition: .3s ease;
          }

          &-text {
            height: 80px;
            margin-top: 15px;
            font-family: PingFangSC-Medium;
            font-weight: 600;
            font-size: 16px;
            color: #FFFFFF;
            letter-spacing: 0;
            text-align: center;
            line-height: 27px;
            transition: .3s ease;
          }

          &-image {
            width: 221px;
            transition: .3s ease;
          }

          &:hover {
            cursor: pointer;

            .screenshot-item-image {
              transform: translateY(-30px);
            }

            .screenshot-item-title,
            .screenshot-item-text {
              transform: translateY(-15px);
            }
          }
        }
      }
    }

    .page-review {
      background: #FCFCFF;
      padding: 80px 0;

      .review-wrap {
        position: relative;
        margin: 0 auto;
        width: 1200px;

        .swiper {
          width: 100%;
          height: 330px;
          text-align: center;
          padding: 100px 0 70px 0;

          .review-item {
            box-sizing: border-box;
            padding: 24px;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 340px;
            color: #6B6B6B;
            background: #FFFFFF;
            border-radius: 16px;
            box-sizing: border-box;
            box-shadow: 0 10px 13px 0 rgba(126,136,255,0.18);

            &.slide-active {
              color: #FFFFFF;
              background: #4361FF;
              box-shadow: 0 10px 13px 0 rgba(126,136,255,0.46);

              .review-item {
                &-header {
                  border: 4px solid #4361FF;
                }
                &-name {
                  color: #FFFFFF;
                }
                &-from {
                  color: #FFFFFF;
                }
              }
            }

            &-header {
              width: 70px;
              height: 70px;
              border-radius: 40px;
              border: 3px solid white;
              margin-top: -64px;

              img {
                width: 100%;
              }
            }

            &-name {
              margin-top: 10px;
              font-family: Helvetica-Bold;
              font-weight: Bold;
              font-size: 16px;
              color: #0B2550;
            }

            &-from {
              margin-top: 10px;
              font-family: sans-serif;
              font-size: 14px;
              color: #0B2550;
            }
            
            &-stars {
              margin-top: 12px;
              width: 136px;
              display: flex;
              justify-content: space-between;
              img {
                width: 22px;
              }
            }

            &-content {
              margin-top: 16px;
              font-family: sans-serif;
              font-size: 14px;
              text-align: center;
              line-height: 25px;
            }
          }
        }

        .swiper-button {
          position: absolute;
          width: 36px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          top: unset;
          bottom: -20px;

          &:after {
            content: '';
          }

          img {
            width: 100%;
          }
        }

        .swiper-button-prev {
          transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          left: 44%;
        }

        .swiper-button-next {
          right: 44%;
        }
      }
    }

    .page-download {
      background: url(../assets/images/home/download-page-bg.png) center top/cover no-repeat;
      padding-top: 70px;
      padding-bottom: 120px;

      .logo-icon {
        display: block;
        width: 195px;
        margin: 45px auto 75px;
      }

      .download-wrap {
        display: flex;
        justify-content: space-between;
        width: 700px;
        margin: 0 auto;

        .download-link {
          display: block;
          width: 220px;
          height: 68px;
          background: #0B0B0B;
          border-radius: 12px;
          text-decoration: none;
          text-align: center;

          &:hover {
            background: #2D2D2D;
          }

          i {
            color: #FFFFFF;
            font-size: 70px;
            line-height: 68px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .home {
      .page {
        min-width: unset;
      }
      .page-main {
        height: calc(100vh - 80px);
        background: url(../assets/images/home/main-page-bg.png) center bottom/100% no-repeat, linear-gradient(-56deg, #3D33AE 0%, #1E33C2 100%);
        .main-wrap {
          min-width: unset;
          max-width: unset;
          width: 90%;
          margin: 0 auto;
          .image-wrap {
            display: none;
          }
          .slogan-wrap {
            width: 100%;
            text-align: center;
            .slogan {
              white-space: pre-wrap;
              font-size: 2.4rem;
              margin-top: 15vh;
            }
            .guide-text {
              white-space: unset;
            }
            .button-wrap {
              width: 100%;
              justify-content: center;
              margin-bottom: 0;
              bottom: 15vh;
              .download-link {
                margin-right: 0;
                margin-bottom: 2rem;
              }
            }
          }
        }
      }
      .page-description {
        padding-top: 3rem;
        padding-bottom: 0;
        .description-wrap {
          width: 90%;
          justify-content: center;
        }
      }
      .page-screenshot {
        display: none;
      }
      .page-review {
        padding-top: 3rem;
        .review-wrap {
          width: 100%;
          .swiper-button-prev {
            left: 25%;
          }
          .swiper-button-next {
            right: 25%;
          }
        }
      }
      .page-download {
        .download-wrap {
          width: 100%;
          flex-direction: column;
          align-items: center;
          .download-link {
            margin-bottom: 2rem;
          }
        }
      }
    }
  }
</style>